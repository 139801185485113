@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-color-prime: #733DFF;
  --app-color-second: #8F67FF;
}

body {
  margin: 0;
  font-family: "Montserrat", monospace, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Red Hat Mono", monospace, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: 1px solid transparent;
  outline: 0;
}

input:focus {
  border: 1px solid var(--app-color-prime);
}

input:hover {
  border: 1px solid var(--app-color-second);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

textarea {
  border: 1px solid transparent;
  outline: 0;
}

textarea:focus {
  border: 1px solid var(--app-color-prime);
}

textarea:hover {
  border: 1px solid var(--app-color-second);
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.title {
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
}

.bg-prime {
  background-color: var(--app-color-prime);
}

.bg-second {
  background-color: var(--app-color-second);
}

.listitem:hover {
  background-color: var(--app-color-second);
}

.app-mask {
  box-sizing: border-box;
  background-color: rgba(0,0,0,.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1200;
}
