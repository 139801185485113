
:root {
    --toastify-toast-min-height: 32px;
    --toastify-font-family: 'Nunito Sans','Space Grotesk','Space Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    --toastify-color-dark: #1C1E23;
    --toastify-icon-color-success: #20BB75;
}


.Toastify__toast {
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 2px;
    border-radius: 40px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    font-size: 14px;
    cursor: default;
    direction: ltr;
    z-index: 0;
}

.Toastify__toast-theme--dark {
    background: var(--toastify-color-dark);
    color: var(--toastify-text-color-dark);
}

.Toastify__close-button {
    color: var(--toastify-color-light);
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    /*align-self: flex-start;*/
}

.Toastify__close-button--dark {
    color: var(--toastify-color-dark);
}
/*
.Toastify__toast-container {
    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
    position: fixed;
    padding: 4px;
    width: fit-content;
    box-sizing: border-box;
    color: #fff;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width:fit-content;
        padding: 0;
        left: 0;
        margin: 0;
    }
}*/

.Toastify__toast-container {
    width:fit-content;
    max-width: 100vw;
}